
  
  .accordion_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  }
  
  .accordion_title,
  .accordion_content {
    padding: 1rem;
  }
  
  .accordion_content {
    overflow: hidden;
    transition: all 0.6s ease;
  }
  
  @media screen and (max-width: 700px) {
    body {
      font-size: 18px;
    }
  
    .accordion {
      width: 90%;
    }
  }