.wrapper,
.wrapper .img-area
{
background-color: rgba(255,255,255,0.13);
border-radius: 10px;
backdrop-filter: blur(10px);
border: 2px solid rgba(255,255,255,0.1);
box-shadow: 0 0 40px rgba(8,7,16,0.6);
}

.wrapper{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
border-radius: 10px;
margin:2rem;
padding-block:2rem;
color:white;
position:relative;
transition: all 0.3s ease;
}

.profile_img{
width: 200px;
height: 200px;
border-radius: 50%;
object-fit: cover;
object-position: top;
}

.content_container{
    margin-block:1rem;
}

.positionwrapper{
    margin-top:10px;
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:rgba(255,255,255,0.1);
    padding:0.5rem;
    text-align: center;
    border-radius: 0 0 10px 10px;
    font-size:1.3rem;
}

.wrapper:hover{
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0 0 40px rgba(8,7,16,0.8);
    cursor:pointer;
}



