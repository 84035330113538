.tilting_card_content {
  --perspective: 500px;
  --rotateX: 0;
  --rotateY: 0;
  --angle: 1deg;

  position: relative;
  width: 90%;
  margin-inline:auto;
  padding: 2rem;
  display:flex;
  flex-direction:row;
  justify-content:space-around;
  border-radius:5px;
  padding:2rem;
  transform: perspective(var(--perspective)) rotateX(var(--rotateX))
    rotateY(var(--rotateY));
  transition: transform 350ms ease;
  margin-bottom: 2rem;
  color: white;
  transform-style: preserve-3d;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.7px);
  -webkit-backdrop-filter: blur(8.7px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  
}

.mouse_position_tracker {
  position: absolute;
  inset: 0;
}

.mouse_position_tracker > div {
  position: absolute;
  width: calc(100% / 3);
  height: calc(100% / 3);
  z-index: 2;
}

.tilting_card_content:has(.mouse_position_tracker > div:nth-child(1):hover) {
  --rotateX: var(--angle);
  --rotateY: calc(var(--angle) * -1);
}

.tilting_card_content:has(.mouse_position_tracker > div:nth-child(2):hover) {
  --rotateX: var(--angle);
}

.tilting_card_content:has(.mouse_position_tracker > div:nth-child(3):hover) {
  --rotateX: var(--angle);
  --rotateY: var(--angle);
}

.tilting_card_content:has(.mouse_position_tracker > div:nth-child(4):hover) {
  --rotateY: calc(var(--angle) * -1);
}

.tilting_card_content:has(.mouse_position_tracker > div:nth-child(6):hover) {
  --rotateY: var(--angle);
}

.tilting_card_content:has(.mouse_position_tracker > div:nth-child(7):hover) {
  --rotateX: calc(var(--angle) * -1);
  --rotateY: calc(var(--angle) * -1);
}

.tilting_card_content:has(.mouse_position_tracker > div:nth-child(8):hover) {
  --rotateX: calc(var(--angle) * -1);
}

.tilting_card_content:has(.mouse_position_tracker > div:nth-child(9):hover) {
  --rotateX: calc(var(--angle) * -1);
  --rotateY: var(--angle);
}

/* 1st, 4th, 7th */
.mouse_position_tracker > div:nth-of-type(3n - 2) {
  left: 0;
}
/* 2nd, 5th, 8th */
.mouse_position_tracker > div:nth-of-type(3n - 1) {
  left: calc(100% / 3);
}
/* 3rd, 6th, 9th */
.mouse_position_tracker > div:nth-of-type(3n) {
  right: 0;
}

/* 1-3 */
.mouse_position_tracker > div:nth-child(n + 1):nth-child(-n + 3) {
  top: 0;
}

/* 4-6 */
.mouse_position_tracker > div:nth-child(n + 4):nth-child(-n + 6) {
  top: calc(100% / 3);
}

/* 7-9 */
.mouse_position_tracker > div:nth-child(n + 7):nth-child(-n + 9) {
  bottom: 0;
}

/* general styling */
:root {
  --shadow: 0px 1px 2.2px rgba(0, 0, 0, 0.02),
    0px 2.5px 5.3px rgba(0, 0, 0, 0.028), 0px 4.6px 10px rgba(0, 0, 0, 0.035),
    0px 8.3px 17.9px rgba(0, 0, 0, 0.042), 0px 15.5px 33.4px rgba(0, 0, 0, 0.05),
    0px 37px 80px rgba(0, 0, 0, 0.07);
}

.events_img{
  width:100%;
}

.event_card{

}
.img_container{
  flex: 1 1 30%;
  object-fit: cover;
}
.events_content{
  flex: 1 1 70%;
  padding:2rem;
  margin-left:20px;
  font-size:1.4rem;
}

@media screen and (max-width: 1200px) {
  .tilting_card_content{
      flex-direction:column;
      
  }
  /* .events_img{
  width:350px;
  height:250px;
  } */
  .events_content{
      padding:0.5rem;
  }
}