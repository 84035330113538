.body_wrapper{
    background-color: var(--bg-blue);
    padding-top:1rem;
}
.card_container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

