.event_container{
    width:80%;
    margin-inline:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:2rem;
    flex-direction: row;
}

.event_container:nth-child(even){
    flex-direction: row-reverse;
}

.event_content{
    flex: 1 1 60%;
    padding:2rem;
    font-size: 1.3rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    margin-inline:4rem;
    background:rgba(0, 0, 0, 0.24);
    opacity: 0.8;
    color:white;
}

.event_image{
    /* flex: 1 1 40%; */
    width: 40%;
    outline: 2px solid #000;
    background-color: var(--primary-dark-blue);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.event_image > img{
    width:70%;
    object-fit: contain;
}

@media screen and (max-width: 1200px) {
    .event_container, .event_container:nth-child(even){
        flex-direction: column-reverse;
        align-items: center;
    }
    .event_content{
        margin-inline:0;
        margin-top:2rem;
    }
    .event_image{
        width: 80%;
    }
    .event_image > img{
        width:100%;
    }
}