*,*::after,*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.section-header {
  margin-bottom:1rem;
  position: relative;
  padding-bottom: 20px;
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color:white;
}
.section-header::before {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 5px;
  background: #f82249;
  bottom: 0;
  left: calc(50% - 25px);
}
