.hero{
    position:relative;
    height: 100vh;
    margin-block:0px;
}
.particles_container{
    position: absolute;
    inset:0px;
}
.hero_container{
    width:95%;
    margin-inline:auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
}
.hero_container > h1 {
    font-size:3rem;
}

.hero_container > p {
    font-size:1.5rem;
}


.card_container{
    margin:0px;
    padding-block:3rem;
}
.card_container:nth-child(even){
    flex-direction: row-reverse;
    outline:2px solid red;
}
.faq{
    background-color: #232741;
    padding:3rem;
}
.faq > h1{
    color:white;
    text-align: center;
    padding-block:2rem;
    font-size: 2rem;
}

.accordion_container{
    background-color: white;
    width:80%;
    margin-inline:auto;
    margin-block:2rem;
    padding:2rem;
    border-radius: 10px;;
}